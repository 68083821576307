export const url = {

    baseUrl: 'https://ms-maity-backend.onrender.com',

    route: '/api/v1',

    getCategoryAndBrand: '/allcatNbrand',
        
    getAllProduct:'/allproduct',
    getProductById:'',
     getProductByCategory:"/productByCategory",
     getProductByBrand:'/productByBrand',

     getallfeaturesPhotos:'/allFeaturePhotos'



}